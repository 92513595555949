<script setup lang="ts">
import { Category } from '@shopware-pwa/types';
import { useCategorySearch } from '@shopware-pwa/composables-next';
import { navigationPageParams } from '~/config/defaultApiParams';
import { navigationId } from '~/composables/useNavigation';

const props = defineProps<{
  navigationId: string;
}>();

const { search } = useCategorySearch();
const route = useRoute();
const router = useRouter();

// Send the information about navigationId to the higher levels of the components
// eslint-disable-next-line vue/no-setup-props-destructure
navigationId.value = props.navigationId;
watch(route, (route) => (navigationId.value = props.navigationId));

const cacheKey = computed(
  () =>
    `cmsNavigation_${props.navigationId}_${JSON.stringify(route.query || {})}`
);

const { data: categoryResponse, error } = await useAsyncData(
  cacheKey.value,
  async () => {
    const queryParams = route.path.includes('search')
      ? navigationPageParams
      : { ...navigationPageParams, ...route.query };

    return await search(props.navigationId, {
      withCmsAssociations: false, // false because we set all associations in navigationPageParams
      query: queryParams,
    });
  }
);

if (error.value) {
  router.push('error');
}

const { category } = useCategory(categoryResponse as Ref<Category>);

// category breadcrumbs LSWS-793, PDP has it in FrontendDetailPage
const breadcrumb = category.value.breadcrumb || [];
const newBreadcrumb: any = breadcrumb[0];

if (breadcrumb[0].length > 1) {
  useBreadcrumbs(newBreadcrumb);
} else {
  useBreadcrumbs([]);
}

const { initDataLayer, pushEvents } = useJentis();

// props.navigationId exists for all pages including search also
if (props.navigationId && props.navigationId !== 'undefined') {
  const dataLayer = initDataLayer({
    ...(route.path.includes('search')
      ? { resourceType: 'frontend.search.page', searchTerm: route.query.query }
      : {
          resourceType: 'frontend.cms.navigation.page',
          navigationId: props.navigationId,
        }),
  });

  dataLayer.load().then((result) => pushEvents(result?.data));
}
</script>

<template>
  <CmsPage v-if="category?.cmsPage" :content="category.cmsPage" />
</template>
